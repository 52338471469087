<template>
  <div class="app-container">
    <el-header class="ui-header">{{ $t('title.BasicInformation') }}</el-header>
    <el-form ref="shipmentFormRef" :model="shipmentForm" :rules="rules" label-width="120px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="shipmentID" prop="shipmentId">
            <el-input v-model.trim="shipmentForm.shipmentId" class="w200" :placeholder="$t('page.inputPlaceholder')+'shipmentID'" @blur="shipmentBlur" @keyup.enter.native="shipmentBlur" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="shipmentName" prop="shipmentName">
            <el-input v-model="shipmentForm.shipmentName" class="w200" placeholder="" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('page.outboundOrderNo')" prop="shipmentName" :class="$i18n.locale">
            <el-input v-model="shipmentForm.outboundCode" class="w200" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item :label="$t('page.outboundType')" prop="shipmentName">
            <el-input v-model="shipmentForm.outboundTypeLabel" class="w200" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item :label="$t('page.sendToPlatform')" prop="shipmentName" :class="$i18n.locale">
            <PlatformSelect v-model="shipmentForm.sendSellPlatformId" class="w200" :placeholder="$t('page.selectPlaceholder')" disabled />
        </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item :label="$t('page.sendToSite')" prop="shipmentName">
            <el-input v-model="shipmentForm.sendSiteCode" class="w200" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!-- <el-col :span="8">
          <el-form-item :label="$t('page.destinationPort')" prop="shipmentName">
            <el-input v-model="shipmentForm.targetWarehouseName" class="w200" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col> -->
        <el-col :span="16">
          <el-form-item label="备注" prop="shipmentName">
            <el-input v-model="shipmentForm.remark" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-header class="ui-header">{{ $t('page.ShipmentDetails') }}</el-header>
    <el-table
      ref="shipmentDetailTable"
      v-loading="shipmentTableLoading"
      class="mb-3"
      :data="shipmentDetailTable"
      :header-cell-style="{background:'#fafafa'}"
      :max-height="400"
      :row-style="isRed"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :selectable="checkSelectable"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="destinationPlatSku"
        label="平台SKU"
        align="center"
      />
      <el-table-column
        prop="fnsku"
        label="FNSKU"
        align="center"
      />
      <el-table-column
        prop="upc"
        label="UPC"
        width="120"
        align="center"
      />
      <el-table-column
        prop="style"
        :label="$t('page.Specifications')"
        width="120"
        align="center"
      >
        <template slot-scope="scope"> {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }} </template>
      </el-table-column>
      <el-table-column
        label="箱数"
        width="120"
        align="center"
      >
        <template slot-scope="scope"> {{ getQuantity(scope.row.expectPairs, scope.row.boxNumber) }} </template>
      </el-table-column>
      <el-table-column
        prop="expectPairs"
        label="预期数量"
        width="120"
        align="center"
      />
      <el-table-column
        prop="mwsPairs"
        :label="$t('page.amazonNumber')"
        width="130"
        align="center"
      />
      <el-table-column
        prop="diffPairs"
        label="差异数量"
        width="130"
        align="center"
      />
      <!-- <el-table-column
        prop="diffPairs"
        :label="$t('title.shipemtPairs')"
        width="120"
        align="center"
      /> -->
      <el-table-column
        fixed="right"
        :label="$t('page.operate')"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" :disabled="scope.row.mwsPairs > 0" @click="handleDelete(scope.row, scope.$index)">{{ $t('page.delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="center">
      <el-button type="primary" @click="createAmazonShipment">{{ $t('page.createShipment') }}</el-button>
    </el-row>
  </div>
</template>
<script>
import { Mixin } from '@/mixin/mixin.js'
import { findOutbound, findShipmentId, addShipment, findOutboundAmazonDetail } from '@/api/stock-out'
// import PlatformSelect from '@/components/ListSelection/platform'

export default {
  components: {
    // PlatformSelect
  },
  mixins: [Mixin],
  data() {
    return {
      shipmentForm: {
        outboundCode: '',
        sendSiteCode: '',
        shipmentId: '',
        shipmentName: '',
        outboundType: '',
        sendSellPlatformId: '', // 发至平台id
        targetWarehouseName: '', // 目的港口/仓库
        remark: ''
      },
      shipmentTableLoading: false,
      multipleSelection: [],
      shipmentDetailTable: [],
      rules: {
        shipmentId: [{ required: true, message: this.$t('title.PleasshipmentID'), trigger: 'blur' }]
      }
    }
  },
  mounted() {
    const { outboundCode, outboundType, siteCode } = this.$route.query
    this.shipmentForm.outboundCode = outboundCode
    this.shipmentForm.outboundTypeLabel = this.getOutboundTypeLabel(outboundType)
    this.shipmentForm.sendSiteCode = siteCode
    // this._findOutbound(outboundCode)
  },
  methods: {
    checkSelectable(row) {
      return row.expectPairs > 0 && row.expectPairs === row.mwsPairs
    },
    isRed({ row }) {
      if (!(row.expectPairs > 0 && row.expectPairs === row.mwsPairs)) {
        return {
          backgroundColor: '#DE6',
          color: 'red'
        }
      }
    },
    getQuantity(expectPairs, boxNumber) {
      return (expectPairs / boxNumber).toFixed(2)
    },
    shipmentBlur() {
      if (!this.shipmentForm.shipmentId || this.shipmentTableLoading) return
      this._findShipmentId()
      this._findDetailAndMws()
    },
    handleDelete(row, index) {
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    createAmazonShipment() {
      /** 校验是否有飘红的明细，有给出提示 */
      if (!this.multipleSelection.length) {
        this.$message({
          message: '请选择',
          type: 'warning'
        })
        return
      }
      this.$refs.shipmentFormRef.validate((valid) => {
        if (!valid) return false
        let hasDIff = false // 列表里存在差异
        for (const item of this.shipmentDetailTable) {
          if (item.diffPairs === null || item.diffPairs && item.diffPairs !== 0) hasDIff = true
        }
        if (hasDIff) {
          this.$confirm('', this.$t('page.Prompt'), {
            confirmButtonText: this.$t('page.sure'),
            cancelButtonText: this.$t('title.cancel'),
            type: 'warning'
          }).then(() => {
            this._createShipmentManual({
              // detailList: this.multipleSelection,
              // shipment: this.shipmentForm
            })
          })
        } else {
          this.$confirm('', this.$t('page.Prompt'), {
            confirmButtonText: this.$t('page.sure'),
            cancelButtonText: this.$t('title.cancel'),
            type: 'warning'
          }).then(() => {
            this._createShipmentManual({
              // detailList: this.multipleSelection,
              // shipment: this.shipmentForm
            })
          })
        }
      })
    },
    async _findOutbound(outboundCode) {
      const { datas: { sendSiteCode, outboundType, sendSellPlatformId }} = await findOutbound(outboundCode)
      this.shipmentForm.sendSiteCode = sendSiteCode
      this.shipmentForm.outboundType = outboundType
      this.shipmentForm.outboundTypeLabel = this.getOutboundTypeLabel(outboundType)
      this.shipmentForm.sendSellPlatformId = sendSellPlatformId
    },
    async _findShipmentId() {
      const { datas } = await findShipmentId(this.shipmentForm.shipmentId)
      console.log(datas)
    },
    async _findDetailAndMws() {
      try {
        this.shipmentTableLoading = true
        const { shipmentId, outboundCode } = this.shipmentForm
        const { platformCode, shopCode, siteCode, tableSuffix } = this.$route.query
        const { datas } = await findOutboundAmazonDetail({
          outboundCode,
          platformCode,
          shopCode,
          siteCode,
          tableSuffix,
          shipmentId
        })
        this.shipmentDetailTable = Array.isArray(datas.item) && datas.item || []
        this.shipmentForm.shipmentName = datas.shipmentName
        this.shipmentForm.targetWarehouseName = datas.destinationFulfillmentCenterId
      } catch (error) {
        this.$message.error(error)
      } finally {
        this.shipmentTableLoading = false
      }
    },
    async _createShipmentManual(data) {
      const { outboundCode, tableSuffix } = this.$route.query
      const params = {
        detailList: this.multipleSelection,
        outboundCode,
        shipmentId: this.shipmentForm.shipmentId,
        tableSuffix
      }
      await addShipment(params)
      this.$message({
        type: 'success',
        message: '操作成功'
      })
      // this.$router.push({
      //   path: '../ship-management'
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
.ui-header {
  height: 30px!important;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}
</style>
